<template>
  <div>
    <div class="mb-2" v-if="!!title">
      <span class="text-primary">{{title}}</span>
      <div class="border-top" style="margin-top:5px"></div>
    </div>
    <div class="row" v-if="!loading">
      <slot name="message" />
      <div v-for="item in data" :key="item.id" class="col-12 col-md-6 mb-2">
        <card-item shadow>
          <div v-if="$scopedSlots.header" class="spacing" :class="classHeader"><slot name="header" :item="item"/></div>
          <div v-if="$scopedSlots.header" class="border-top"></div>
          <div v-if="$scopedSlots.main" class="spacing"><slot name="main" :item="item"/></div>
          <div v-if="$scopedSlots.footer" class="spacing"><slot name="footer" :item="item"/></div>
        </card-item>
      </div>
      <div v-if="data.length===0" class="d-flex flex-column justify-content-center align-items-center" style="height:240px;width:100%">
        <div>
          <feather-icon icon="FilePlusIcon" size="52" style="color:#B9B9C7"/>
        </div>
        <div class="text-wrap text-center my-1" style="width:80%;font-size:14px;color:#B9B9C7">
          {{emptyMsg}}
        </div>
      </div>
    </div>
    <div class="row" v-else>
      <div v-for="(item, index) in new Array(sizeSkeleton)" :key="index" class="col-12 col-md-6 mb-2">
        <b-skeleton animation="fade" width="100%" height="200px" style="border-radius:16px"></b-skeleton>
      </div>
    </div>
  </div>
</template>
<script>
import CardItem from './CardItem.vue'
export default {
  components: { CardItem },
  props : {
    data: { type: Array, default: () => ([]) },
    shadow: { type: Boolean, default: false },
    title: { type: String, default: '' },
    loading: { type: Boolean, default: false },
    sizeSkeleton: { type: Number, default: 2 },
    emptyMsg: { type: String, default: ''},
    classHeader: {type: Array, default: () => []}
  },
  data () {
    return {}
  }
}
</script>
<style>
.spacing {
  padding: 1rem 2rem;
}
</style>