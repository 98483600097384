<template>
  <list-integrations v-if="mySession.role" />
</template>
<script>
import { mapGetters } from 'vuex'
import ListIntegrations from './ListIntegrations.vue'
export default {
  components: { ListIntegrations },
  computed: {
    ...mapGetters({
      mySession: 'getSession'
    })
  }
}
</script>

