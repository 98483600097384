<template>
  <div style="min-height: 125px">
    <b-card style="min-height: 125px">
      <filter-swapper v-if="fieldsSelect.length !== 0" :buttons="buttons" :controlHeightButtons="controlHeight">
        <template #slot1>
          <form-render 
          :form.sync="formSelect" 
          :key="keyFormRender" 
          :fields="fieldsSelect" 
          @send="filterList"
          :buttonSend="buttonSend"
          ref="formFilter"
          containerButtonsClass="col-sm-12 col-md-7 col-lg-4 col-xl-3 container-button mt-2" class="mb-2">
            <template #buttons>
              <b-button variant="outline-light" v-b-tooltip.hover title="Limpiar filtros" class="ml-1" @click="cleanFilter"><feather-icon icon="RefreshCwIcon"/></b-button>
            </template>
          </form-render>
        </template>
      </filter-swapper>
      <div v-if="loading.second && rows.length===0 && available.length===0" class="d-flex flex-column justify-content-center align-items-center" style="height: 470px">
        <div class="col-lg-6">
          <div class="text-center">
            <feather-icon class="text-center" icon="FileTextIcon" size="52" style="color:#B9B9C7"/>
          </div>
          <div class="text-wrap text-center my-1" style="font-size:14px;color:#B9B9C7">
            Utiliza los selectores disponibles en la parte superior para realizar una búsqueda eficiente y acceder a la información deseada.
          </div>
        </div>
      </div>
      <!-- // mis integraciones -->
      <div v-show="!loading.second" class="mt-2">
        <cards-render
        :data="rows"
        title="Mis integraciones"
        :classHeader="['spacing-header']"
        :loading="loading.marketcenters"
        :emptyMsg="$t('Selecciona y activa el canal de venta que desees utilizar para comenzar el procesamiento de tus envíos.')">
          <template slot="header" slot-scope="{item}">
            <div class="d-flex justify-content-between">
              <div class="d-flex align-items-center">
                <img :src="item.icon" alt="logo integration" width="115px">
                <div>
                  <b-dropdown size="sm"  variant="link" toggle-class="text-decoration-none" no-caret>
                    <template #button-content>
                      <feather-icon icon="MoreVerticalIcon" size="18" class="text-warning"/>
                    </template>
                    <b-dropdown-item @click="goEdit(item.code)">Editar</b-dropdown-item>
                    <b-dropdown-item @click="openModalUnlik(item.code)">Desvincular</b-dropdown-item>
                  </b-dropdown>
                </div>
              </div>
              <div class="d-flex justify-content-center align-items-center">
                <b-form-checkbox
                v-if="!loadingIntegrations[item.code]"
                :checked="!item.is_disable_webhook"
                @change="onChangeStatusIntegration(item.code, shipperIdParams)"
                v-b-tooltip.hover.bottom="`${!item.is_disable_webhook ? 'Desactivar' : 'Activar'} la creación de órdenes`"
                :id="`is_active${item.code}`" switch></b-form-checkbox>
                <b-spinner v-if="loadingIntegrations[item.code]" variant="success"></b-spinner>
              </div>
            </div>
          </template>
          <template slot="main" slot-scope="{item}">
            <div style="min-height:4em">
              <p style="font-size:13px">{{item.description || 'Potencia tu negocio en Falabella, que combina retail físico y digital, servicios financieros y programas de lealtad.'}}</p>
            </div>
          </template>
          <template slot="footer" slot-scope="{item}">
            <div class="d-flex align-items-center">
              <span class="custom-badge">FECHA DE CREACIÓN&nbsp;{{$options.filters.moment(item.created_at, 'YY.MM.DD')}}</span>&nbsp;&nbsp;
              <feather-icon icon="SettingsIcon" size="14" style="color:#044389"/>&nbsp;<a :href="item.documentation" target="_blank" style="color:#044389;font-size:8px;font-weight:bolder;">GUÍA DE CONFIGURACIÓN</a>
            </div>
          </template>
        </cards-render>
        <!-- // disponibles -->
        <cards-render 
        :data="available" 
        :classHeader="['spacing-header']"
        title="Disponibles" 
        :loading="loading.marketcenters"
        :sizeSkeleton="4"
        :emptyMsg="$t('No hay integraciones disponibles.')">
          <template slot="message">
            <div class="col-12">
              <div class="col-12 col-lg-6 pl-0 pr-sm-0 pr-lg-1">
                <div class="alert d-flex align-items-center">
                  <div>&nbsp;<feather-icon icon="InfoIcon" size="22" style="color:#0169DE"/></div>&nbsp;&nbsp;
                  <div>
                    <span style="font-size: 8px">Actualmente, los canales de venta <strong style="color:#0169DE">'Hites, Kitchen Center, Paris y Wallmart'</strong> no permiten integración automática.</span><br>
                    <span style="font-size: 8px">Si deseas conectar alguno de estos canales a tu cuenta, ponte en contacto con nuestro equipo de Support enviando un correo a <strong style="color:#0169DE">[support@enviame.io]</strong>.</span><br>
                    <span style="font-size: 8px">Una vez que el equipo de Support haya completado la integración, verás el canal de venta activo en la sección Canales de Venta dentro de Mis Integraciones.</span>
                  </div>
                </div>
              </div>
            </div>
          </template>
          <template slot="header" slot-scope="{item}">
            <div class="d-flex justify-content-between">
              <div class="d-flex align-items-center"><img :src="item.icon" alt="logo integration" width="115px"></div>
              <div v-if="hasWarehouses && canViewConnectButton(item.code)" class="d-flex justify-content-center align-items-center"><b-button variant="warning" @click="goDetail(item.code)">CONECTAR</b-button></div>
            </div>
          </template>
          <template slot="main" slot-scope="{item}">
            <div style="min-height:4em">
              <p style="font-size:13px">{{item.description || 'Potencia tu negocio en Falabella, que combina retail físico y digital, servicios financieros y programas de lealtad.'}}</p>
            </div>
          </template>
          <template slot="footer" slot-scope="{item}">
            <div class="d-flex align-items-center">
              <feather-icon icon="SettingsIcon" size="14" style="color:#044389"/>&nbsp;<a :href="item.documentation" target="_blank" style="color:#044389;font-size:8px;font-weight:bolder;">GUÍA DE CONFIGURACIÓN</a>
            </div>
          </template>
        </cards-render>
      </div>
    </b-card>
    <b-modal
    id="modalUnlink"
    :title="$t(`CANAL DE VENTA - ${marketcenterName.toUpperCase()}`)"
    size="lg" hide-footer
    no-close-on-esc no-close-on-backdrop centered
    no-footer
    @hide="successDelete=false"
    >
      <div :class="{'hidden': successDelete}">
        <div class="d-flex justify-content-center align-items-center flex-column mt-3 mb-5">
        <feather-icon icon="AlertTriangleIcon" size="110" stroke-width="1" class="text-warning mb-2"/>
        <p class="text-center" style="font-size:16px">¿Estás seguro que deseas desvincular la integración con {{marketcenterName}}?<br>Recuerda que al hacerlo perderás la capacidad de gestionar tus ventas desde nuestra plataforma.</p>
        <div class="mt-2 mb-5 row justify-content-center">
          <div class="col-12 col-lg-6 mb-1 mb-lg-0 text-center">
          <b-button @click="hideModal('modalUnlink')" variant="outline-danger" style="width:230px;">No</b-button>
          </div>
          <div class="col-12 col-lg-6 text-center">
          <b-button :disabled="loadingFields.deleteIntegration" @click="deleteIntegration" variant="outline-success" style="width:230px;">
            <b-spinner v-show="loadingFields.deleteIntegration" small></b-spinner>&nbsp;Si</b-button>
          </div>
        </div>
        </div>
      </div>
      <div :class="{'hidden': !successDelete}">
        <div class="d-flex justify-content-center align-items-center flex-column mt-3 mb-5">
        <feather-icon icon="CheckCircleIcon" size="110" class="text-success mb-2"/>
        <p class="text-center" style="font-size:16px">¡La integración con {{marketcenterName}} se ha desvinculado exitosamente!<br>Recuerda que ya no podrás gestionar tus ventas desde nuestra plataforma.</p>
        <div class="mt-2 mb-5">
          <b-button @click="hideModal('modalUnlink')" variant="outline-warning" class="mr-2" style="width:230px;">Aceptar</b-button>
        </div>
        </div>
      </div>
    </b-modal>
    <b-modal
    id="modalNoWarehouse"
    :title="$t('CANALES DE VENTA')"
    size="lg" hide-footer
    no-close-on-esc no-close-on-backdrop centered
    no-footer
    @hide="successDelete=false"
    >
      <div>
        <div class="d-flex justify-content-center align-items-center flex-column mt-3 mb-5">
        <feather-icon icon="AlertTriangleIcon" size="110" stroke-width="1" class="text-warning mb-2"/>
        <p style="font-weight:bold;font-size:16px">¡Bodega No Registrada!</p>
        <p class="text-center" style="font-size:16px">Para integrarte con {{marketcenterName}}, primero necesitas crear una bodega.<br>Haz clic en el siguiente enlace para crearla y luego podrás continuar con la integración.</p>
        <div class="mt-2 mb-5">
          <a @click="openWarehouse('ModalWarehouseCompanies')">CREAR NUEVA BODEGA&nbsp;<span style="font-size:20px;">&#128073;</span></a>
        </div>
        </div>
      </div>
    </b-modal>
    <modal-warehouse-companies 
    :formTab="{}"
    :companyId="shipper_id"
    :afterSuccess="afterSaveWarehouse"
    :country="formSelect.country_id"></modal-warehouse-companies>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import ModalWarehouseCompanies from '../../companies/companies-id/warehouses/ModalWarehouseCompanies.vue'
import CardsRender from '../components/CardsRender.vue'
export default {
  components: { CardsRender, ModalWarehouseCompanies },
  data() {
    return {
      controlHeight: { class: 'row mb-2 spacing-label-field'},
      buttonSend: {title: 'Buscar', icon: 'SearchIcon', color: 'warning'},
      buttons: [],
      keyFormRender: 0,
      rows: [],
      available: [],
      myWarehouses: [],
      fieldsSelect: [],
      formSelect: {},
      shipper_id: null,
      country_id: null,
      organizationId: null,
      marketcenter_cod: null,
      successDelete: false,
      loadingFields: {
        organizations: true,
        shippersByOrganization: true,
        deleteIntegration: false,
        countries: false
      },
      loading: {
        marketcenters: false,
        warehouses:false,
        changeStatus: false,
        total: true,
        second: true
      },
      loadingIntegrations: {
        ML_CL: false,
        DAF_CL: false,
        PRM_CL: false,
        RPL_CL: false,
        FLB_CL: false,
        WM_CL: false,
        HTS_CL: false,
        KC_CL: false
      },
      organization_type_id: null,
      itemCode: null
    }
  },
  computed: {
    ...mapGetters({
      mySession: 'getSession',
      organizations: 'getOrganizations',
      shippersByOrganization: 'getShippersByOrganization',
      generalLoading: 'getLoading',
      marketcenters: 'getMarketcenters',
      warehouses: 'getWarehouses',
      fieldsMarketcenter: 'getFieldsMarketcenter',
      countries: 'getCountries',
      paramsMkc: 'getParamsMkc'
    }),
    hasWarehouses() {
      return this.warehouses.length > 0
    },
    shipperIdParams() {
      return this.paramsMkc?.shipper_id
    },
    isUserFreemium() {
      return ['ecommerce'].includes(this.mySession?.role) && this.mySession?.user_access?.company_category_id === 2
    },
    isUserAdmin() {
      return ['admin'].includes(this.mySession?.role)
    },
    marketcenterName() {
      const names = Object.freeze({
        ML_CL: 'Mercado Libre',
        DAF_CL: 'Dafiti',
        PRM_CL: 'Paris',
        RPL_CL: 'Replay',
        FLB_CL: 'Falabella',
        WM_CL: 'Walmart',
        HTS_CL: 'Hites',
        KC_CL: 'Kitchen Center'
      }) 
      return names[this.marketcenter_cod] ?? ''
    }
  },
  watch: {
    marketcenters() {
      this.rows = this.marketcenters.filter((item) => item.is_integrated) ?? []
      this.available = this.marketcenters.filter((item) => !item.is_integrated) ?? []
    },
    warehouses(val) {
      if (val.length === 0) this.showModal('modalNoWarehouse')
      this.myWarehouses = this.warehouses.map((d) => ({ id: d.code, text: d.name }))
    },
    mySession() {
      if (this.mySession.id) this.setInitialData()
    },
    organizations () {
      this.setSelectOptions('organization_id', { options: this.organizations.rows })
    },
    shippersByOrganization () {
      const shipperOptions = this.shippersByOrganization.map((shipper) => ({...shipper, name: shipper.name1, text: shipper.name1 }))
      this.setSelectOptions('shipper_id', { options: shipperOptions })
    },
    generalLoading: {
      handler () {
        this.loadingFields.organizations = !!this.generalLoading.getOrganizations
        this.loadingFields.shippersByOrganization = !!this.generalLoading.getShippersByOrganization
        this.loading.marketcenters = !!this.generalLoading.getMarketcenters
        this.loadingFields.deleteIntegration = !!this.generalLoading.deleteIntegration
        this.loading.changeStatus = !!this.generalLoading.updateStatusIntegration
        this.loadingIntegrations[this.itemCode] = !!this.generalLoading.updateStatusIntegration

        this.setSkeletonField('organization_id', this.loadingFields.organizations)
        this.setSkeletonField('shipper_id', this.loadingFields.shippersByOrganization)
      },
      deep: true
    },
    loading: {
      handler () {
        const prevTotal = this.loading.total
        this.loading.total = !Object.keys(this.loading).filter(key => key !== 'total').every(key => !this.loading[key])
        if (prevTotal !== this.loading.total) this.keyFormRender++
      },
      deep: true
    }
  },
  mounted () {
    this.setInitialData()
    if (this.marketcenters.length > 0) {
      this.rows = this.marketcenters.filter((item) => item.is_integrated) ?? []
      this.available = this.marketcenters.filter((item) => !item.is_integrated) ?? []
      this.loading.second = false
    }
  },
  methods: {
    setInitialData () {
      if (['admin'].includes(this.mySession?.role)) {
        this.fieldsSelect = [
          {fieldType: 'FieldSelect', name: 'country_id', addFlags:true, label: this.$t('País'), useLabel: true, change: this.onChangeCountry},
          {fieldType: 'FieldSelect', name: 'organization_id', label: 'eCommerce/Marketplace', useLabel: true, clearable: true, change: this.changeOrganization, validation: 'required', dependency: 'country_id'}
        ]
        this.setSelectOptions('country_id', { options: this.countries })
      } else if (['marketplace'].includes(this.mySession?.role)) {
        this.organizationId = this.mySession.organization.id
        this.changeOrganization('Organization', this.mySession.organization)
      } else {
        this.shipper_id = this.mySession?.shipper?.id ?? this.shipperIdParams
        this.filterList()
      }
    },
    filterList(form) {
      this.shipper_id = form?.shipper_id?.id ?? this.shipper_id
      this.$store.dispatch('dispatchSetParamsMkc', { ...this.paramsMkc, shipper_id: this.shipper_id })
      this.getMarketcenters()
      if (this.shipper_id) this.getWarehouses(this.shipper_id)
    },
    getMarketcenters() {
      this.loading.second = false
      const data = {
        name: 'getMarketcenters',
        params: { shipper_id: this.shipper_id ?? this.shipperIdParams }
      }
      this.$store.dispatch('fetchService', data)
    },
    getOrganizationsFetch(country_id) {
      const data = { 
        name: 'getOrganizations',
        queryParams: {country_id, simplified: true }
      }
      this.$store.dispatch('fetchService', data)
    },
    getOrganizationByShipper(organization_id) {
      const data = { 
        name: 'getShippersByOrganization',
        queryParams: {page: 1, paginate_by: 99999 },
        params: { organization_id },
        onError: () => {
          this.setSelectOptions('shipper_id', { options: [] })
          this.setSkeletonField('shipper_id', false)
        },
        hideAlert: true
      }
      this.$store.dispatch('fetchService', data)
    },
    getWarehouses(shipper_id) {
      const data = { 
        name: 'getWarehouses',
        params: {shipper_id},
        msg: this.$t('warehouses')
      }
      this.$store.dispatch('fetchService', data)
    },
    onChangeStatusIntegration(code, shipper_id) {
      this.itemCode = code
      const data = {
        name: 'updateStatusIntegration',
        params: { shipper_id, marketcenter_cod: code },
        onSuccess: () => this.getMarketcenters(),
        onError: () => this.getMarketcenters()
      }
      this.$store.dispatch('fetchService', data)
    },
    openModalUnlik(code) {
      this.showModal('modalUnlink')
      this.marketcenter_cod = code
    },
    deleteIntegration() {
      const data = { 
        name: 'deleteIntegration',
        params: {marketcenter_cod: this.marketcenter_cod, shipper_id: this.shipper_id ?? this.shipperIdParams},
        onSuccess: () => { 
          this.successDelete = true
          this.getMarketcenters()
        }
      }
      this.$store.dispatch('fetchService', data)
    },
    changeShipper() {
      this.keyFormRender++
    },
    cleanFilter (e) {
      this.formSelect = {}
    },
    changeOrganization (name, value) {
      const { organization_type } = value
      this.organization_type_id = organization_type.id
      this.organizationId = value.id
      this.fieldsSelect = this.fieldsSelect.filter((field) => field.name !== 'shipper_id')
      if (organization_type.id === 1 && ['admin', 'superadmin'].includes(this.mySession.role)) {
        this.getOrganizationByShipper(this.organizationId)
        this.cleanFields(['shipper_id'])
        this.fieldsSelect.push({fieldType: 'FieldSelect', name: 'shipper_id', label: 'Seller', useLabel: true, placeholder: 'Nombre de la empresa', dependency: 'organization_id', change: this.changeShipper, validation: 'required'})
      } else if (organization_type.id === 1 && ['marketplace'].includes(this.mySession.role)) {
        this.getOrganizationByShipper(this.organizationId)
        this.cleanFields(['shipper_id'])
        this.fieldsSelect.push({fieldType: 'FieldSelect', name: 'shipper_id', label: 'Seller', useLabel: true, placeholder: 'Nombre de la empresa', change: this.changeShipper, validation: 'required'})
      } else {
        delete this.formSelect.shipper_id
        this.shipper_id = this.organizationId
      }
    },
    onChangeCountry(name, value) {
      this.getOrganizationsFetch(value?.id ?? this.country_id)
      this.cleanFields(['shipper_id', 'organization_id'])
    },
    setSkeletonField(name, value) {
      const index = this.fieldsSelect.findIndex(el => el.name === name)
      if (index !== -1) this.fieldsSelect[index].useSkeleton = value
    },
    setSelectOptions (name, { options }) {
      const index = this.fieldsSelect.findIndex(el => el.name === name)
      if (index !== -1) {
        this.fieldsSelect[index].options = options
        this.fieldsSelect[index].disabled = false
      }
      this.keyFormRender++
    },
    cleanFields(array) {
      array.forEach((el) => this.formSelect[el] = null)
      this.keyFormRender++
    },
    goDetail(code) {
      const params = { shipper_id: this.shipper_id ?? this.shipperIdParams, marketcenter_code: code }
      this.$store.dispatch('dispatchSetParamsMkc', {...this.paramsMkc, marketcenter_code: code})
      this.$router.push({ name: 'marketcenter-detail', params })
    },
    goEdit(code) {
      const params = { shipper_id: this.shipper_id ?? this.shipperIdParams, marketcenter_code: code }
      this.$store.dispatch('dispatchSetParamsMkc', {...this.paramsMkc, marketcenter_code: code})
      this.$router.push({ name: 'marketcenter-detail', params: { ...params, edit: 'edit' }})
    },
    showModal(id) {
      this.$bvModal.show(id)
    },
    hideModal(id) {
      this.$bvModal.hide(id)
    },
    openWarehouse(name) {
      this.showModal(name)
      this.hideModal('modalNoWarehouse')
    },
    afterSaveWarehouse() {
      this.getWarehouses(this.shipper_id ?? this.shipperIdParams)
    },
    canViewConnectButton(marketcenter_code) {
      if (this.isUserAdmin) return true
      return !['PRM_CL', 'WM_CL', 'HTS_CL', 'KC_CL'].includes(marketcenter_code)
    }
  }
}
</script>
<style>
  .custom-switch > .custom-control-input:checked ~ .custom-control-label::before {
    border-color: #1E8E3E;
    background-color: #1E8E3E;
  }
  .custom-badge {
    background-color: #7B9BC1;
    color: white;
    padding: 0.5rem 1rem;
    border-radius: 4px;
    font-size: 8px;
    font-weight: bold;
  }
  .spacing-header {
    padding: 0rem 2rem !important;
  }
  .alert {
    background-color: #E6EEFA;
    border: #D0E2F8 2px solid;
  }
</style>