<template>
  <div class="card-custom" :class="{ 'card-shadow': shadow }">
    <slot/>  
  </div>
</template>
<script>
export default {
  props : { 
    shadow: { type: Boolean, default: false }
  },
  data () {
    return {}
  }
}
</script>
<style>
.card-custom {
  width: 100%;
  background-color: #F2F2F8;
  border-radius: 16px;
}
.card-shadow { 
  -webkit-box-shadow: 0px 2px 2px 0.2px rgba(175,175,175,1);
  -moz-box-shadow: 0px 2px 2px 0.2px rgba(175,175,175,1);
  box-shadow: 0px 2px 2px 0.2px rgba(175,175,175,1);
}
</style>